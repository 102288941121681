// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'

import PropTypes from '@ttn-lw/lib/prop-types'

const Section = ({ className, component: Component, children, ...rest }) => (
  <Component className={className} {...rest}>
    {React.Children.map(children, row =>
      React.cloneElement(row, {
        head: Component === 'thead',
        body: Component === 'tbody',
        foot: Component === 'tfoot',
      }),
    )}
  </Component>
)

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** The HTML name of the section component. */
  component: PropTypes.oneOf(['thead', 'tbody', 'tfoot']).isRequired,
}

Section.defaultProps = {
  children: undefined,
  className: undefined,
}

export default Section
