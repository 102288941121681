// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import CancelablePromise from 'cancelable-promise'

import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'

const useRequest = requestAction => {
  const dispatch = useDispatch()
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState('')
  const [result, setResult] = useState()

  useEffect(() => {
    const promise = (requestAction instanceof Array
      ? CancelablePromise.all(requestAction.map(req => dispatch(attachPromise(req))))
      : dispatch(attachPromise(requestAction))
    )
      .then(() => {
        setResult(result)
        setFetching(false)
      })
      .catch(error => {
        setError(error)
        setFetching(false)
      })

    return () => {
      // Cancel the promise on unmount (if still pending).
      promise.cancel()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [fetching, error, result]
}

export default useRequest
